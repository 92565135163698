/* ---------- root variables ---------- */
:root {
  --green: rgb(38, 76, 89);
  --light-green: rgb(172, 198, 201);
  --light-grey: #f1f1f4;
  --text-color-light: #f1f1f1;
  --text-color-dark: #333;
  --text-color-danger: rgb(152, 58, 58);
  --white: #f1f1f1;
}
/* -------------Font---------------- */
@font-face {
  font-family: "Hurme Geometric Sans 4";
  src: url("./HurmeGeometric/HurmeGeometricSans4-Thin.eot");
  src: url("./HurmeGeometric/HurmeGeometricSans4-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./HurmeGeometric/HurmeGeometricSans4-Thin.woff") format("woff"),
    url("./HurmeGeometric/HurmeGeometricSans4-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Hurme Geometric Sans 4";
  src: url("./HurmeGeometric/HurmeGeometricSans4-SemiBold.eot");
  src: url("./HurmeGeometric/HurmeGeometricSans4-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./HurmeGeometric/HurmeGeometricSans4-SemiBold.woff") format("woff"),
    url("./HurmeGeometric/HurmeGeometricSans4-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Hurme Geometric Sans 4";
  src: url("./HurmeGeometric/HurmeGeometricSans4-LightObl.eot");
  src: url("./HurmeGeometric/HurmeGeometricSans4-LightObl.eot?#iefix")
      format("embedded-opentype"),
    url("./HurmeGeometric/HurmeGeometricSans4-LightObl.woff") format("woff"),
    url("./HurmeGeometric/HurmeGeometricSans4-LightObl.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

body {
  color: var(--text-color-dark);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
li {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  padding: 58px 0 0;
  background-color: var(--white);
}

h1 {
  font-family: "Hurme Geometric Sans 4";
  font-size: 4em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  padding: 0.2em;
}

h2 {
  font-family: "Hurme Geometric Sans 4";
  font-size: 3em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

h3 {
  font-family: "Hurme Geometric Sans 4";
  font-size: 2em;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

a {
  color: var(--green);
}

.page {
  /* background-color: var(--light-green); */
  min-height: calc(100vh - 58px - 2em);
  /* padding: 1em; */
}

.text-center {
  text-align: center;
}
.text-error {
  color: var(--text-color-danger);
}

.text-error:first-letter {
  text-transform: uppercase;
}

/* ---------- nav styling ---------- */
nav {
  background-color: grey;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;
}

/* Style the links inside the navigation bar */
nav a {
  display: inline-block;
  color: var(--text-color-light);
  text-align: center;
  padding: 20px 16px;
  text-decoration: none;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

/* Change the color of links on hover */
nav a:hover {
  background-color: var(--light-grey);
  color: var(--text-color-dark);
}

/* Add a color to the active/current link */
nav a.active {
  background-color: #d71d83;
  color: white;
}
.lang-form {
  width: 60px;
  display: inline-block;
}
.english {
  padding-left: 25px;
  padding-top: 1px;
  padding-bottom: 1px;
  background-image: url("img/uk.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: grey;
  font-size: 12px;
  cursor: pointer;
}
.dansk {
  padding-left: 25px;
  padding-top: 1px;
  padding-bottom: 1px;
  background-image: url("img/dk.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: grey;
  font-size: 12px;
  cursor: pointer;
}
#dansk,
#english {
  display: none;
}
/* --------------Hero styling------------------ */

.hero {
  background-image: url("img/Hero2.jpg");
  max-width: 100vw;
  min-height: 30vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Typewriter {
  padding: 0.3em;
  font-size: 2em;
  text-align: right;
}
.Typewriter__wrapper {
  font-family: "Hurme Geometric Sans 4";
  text-transform: uppercase;
}
/* ----------Main styling------------------- */
/* .main {
    padding: 1em;
} */

.main-intro {
  background-color: white;
  padding: 1em;
  background-image: url("img/trekant2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-article {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  background-image: url("img/chevron.png");
  /* background-size: cover; */
}
.main-article > p {
  padding-bottom: 3em;
}
.main-pics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4em;
}
.main-pics > img {
  max-width: 40vw;
}

.skills > h2 {
  padding: 0.3em;
}
.skills-box {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
}
.skill-deets {
  background-image: url("img/detail.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
.skill-adobe {
  background-image: url("img/adobe.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
.skill-design {
  background-image: url("img/design.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
.skill-web {
  background-image: url("img/web.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
.skill-react {
  background-image: url("img/react.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
.skill-some {
  background-image: url("img/video.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding: 1em;
}
/* ---------- grid container styling ---------- */
.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
}
.projects-container > article {
  /* box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25); */
  padding: 1.5em;
  background-color: white;
  transition: 0.5s;
  animation: fadeIn 0.5s;
}

.projects-container > article ::first-letter {
  text-transform: uppercase;
}

.projects-container > article:hover {
  box-shadow: 0 8px 16px 0 var(--green);
}

.projects-container > article img {
    width: 100%;
}
.item {
  min-width: 90vw;
  padding: 5px;
}
.item img {
  width: 90%;
  /* height: 100%; */
}
/* .inner-carousel {
  display: flex;
} */
/* .carousel {
  width: 85vw;
  cursor: grab;
  overflow: hidden;
} */
.project {
  background-image: url("img/assymmetri2.png");
}
.project > div {
  text-align: right;
}
.project > div > a {
  font-family: "Hurme Geometric Sans 4";
  color: #d71d83;
  text-decoration: none;
}
.about > article > img {
  width: 100vw;
}
.aboutme {
  background-color: white;
  background-image: url("img/chevron2.png");
  padding: 1em;
}
.aboutme > p {
  padding-bottom: 2em;
}
.hobbies {
  background-color: white;
  background-image: url("img/trekant.png");
  background-size: cover;
  padding: 1em;
}

.btn {
  display: flex;
  justify-content: center;
}

.btn > a {
  background-color: #d71d83;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 70px;
}
@media (min-width: 700px) {
  .lang-form {
    width: 100px;
  }
  .english {
    padding-top: 5px;
    padding-right: 5px;
    margin: 0 5px;
    padding-left: 30px;
    color: white;
  }
  .dansk {
    padding-top: 5px;
    padding-left: 30px;
    margin: 0 5px;
    color: white;
  }
  .Typewriter {
    font-size: 3em;
  }
  .skills-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }
  .main-article {
    background-repeat: no-repeat;
    background-position: right;
  }
  .projects-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5em;
    margin: 0 1em;
  }
  .project {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .project > h2 {
    margin: 0.5em 1em;
  }
  .project > div > a {
    font-size: 2rem;
    margin: 0.5em;
  }
  .project > div {
    text-align: left;
  }
  .project-tools > h3 {
    margin-top: 0;
  }
  .item {
    min-width: 90vw;
    padding: 5px;
  }
  .item img {
    width: 90%;
    /* height: 100%; */
  }
  .inner-carousel {
    display: flex;
  }
  .carousel {
    width: 85vw;
    cursor: grab;
    overflow: hidden;
    margin: 0 auto;
  }
  .aboutme {
    background-repeat: no-repeat;
  }
  .aboutme > p {
    margin: 1em 4em;
  }
}

@media (min-width: 992px) {
  .hero {
    max-width: 100vw;
    max-height: 100vh;
  }
  .item {
    min-width: 40vw;
    min-height: 40vh;
    padding: 15px;
    cursor: grab;
  }
  .item img {
    /* width: 85%; */
    pointer-events: none;
    width: 35%;
    cursor: grab;
    height: 100%;
  }
  /* .inner-carousel {
    display: flex;
    cursor: grab;
  } */
  /* .carousel {
    width: 35vw;
    cursor: grab;
    overflow: hidden;
    margin: 0 auto;
  } */
}
@media (min-width: 1200px) {
  .hero {
    max-width: 100vw;
    min-height: 90vh;
  }
  .Typewriter {
    padding: 1em;
    font-size: 3em;
    text-align: right;
  }
  .main-intro {
    display: flex;
    justify-content: center;
  }
  .main-intro > p {
    max-width: 800px;
  }
  .skills > h2 {
    text-align: center;
  }
  .skills-box {
    max-width: 1100px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .main-article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-article > p {
    max-width: 800px;
  }
  h1 {
    text-align: center;
  }
  .projects-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .about > article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about > article > img {
    max-width: 50em;
  }
}
